import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import Select from "react-select";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Flip } from "react-toastify";
import {
  selectCartItems,
  selectCartTotal,
} from "../../redux/cart/cart.selectors";
import { clearCart } from "../../redux/cart/cart.actions";
import { API_BASE_URL } from "../../config";

const ChubbysStepper = ({
  cartItems,
  total,
  tax = total * 0.08,
  clearCart,
  freebies,
  fivePackPromoSelection,
  promoCode,
  promotionValue,
  validatePromoCode,
}) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        width: "100%",
        padding: 20,
      },
      button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      actionsContainer: {
        marginBottom: theme.spacing(2),
      },
      resetContainer: {
        padding: theme.spacing(3),
      },
    })
  );

  const [error, setError] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [addy, setAddy] = useState("");
  const [stateOfResidence, setStateOfResidence] = useState("");
  const [countryOfResidence, setCountryOfResidence] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [payPalApproved, setPayPalApproved] = useState(false);
  const [readyToCheckout, setReadyToCheckout] = useState(false);
  const [shippingCost, setShippingCost] = useState(6);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);

  const history = useHistory();

  const sendOrderSummaryEmails = () => {
    let items = [];

    if (cartItems.length === 0) {
      return alert("Empty cart items");
    }

    setLoadingCheckout(true);

    cartItems.forEach((cartItem) => {
      let itemObject = {
        name: cartItem.title,
        qty: cartItem.quantity,
        price: cartItem.price,
        straintype: cartItem.strainClass,
      };

      items.push(itemObject);
    });
    const formatted5packPromoString = (stringOne, string2) => {
      return `${stringOne}  &&   ${string2}`;
    };


    let orderSummary = {
      total: total + shippingCost + tax,
      shipping: promoCode === "CSSFS" || "Vetcodes" || "CSSOZ" ? 0 : shippingCost,
      fname: firstName,
      lname: lastName,
      phone: phoneNumber,
      email,
      city,
      state: stateOfResidence,
      country: countryOfResidence,
      addy,
      zipcode: zipCode,
      promoCode: promoCode || "No promo Code provided",
      items,
      usersSelectedPromo5packs: fivePackPromoSelection[1] ? formatted5packPromoString(
        fivePackPromoSelection[0].label,
        fivePackPromoSelection[1].label
      ):" ",
      freebies: freebies.map((item) => {
        return {
          name: item.title,
          qty: item.qty,
          straintype: item.strainClass,
        };
      }),
    };

    const options = {
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: "POST",
      mode: "cors",
    };
    options.body = JSON.stringify(orderSummary);

    fetch(`${API_BASE_URL}/orderSummaryEmailSenderAPI`, options)
      .then((response) => response.body)
      .then((body) => {
        const reader = body.getReader();

        return new ReadableStream({
          start(controller) {
            return pump();
            async function pump() {
              const { done, value } = await reader.read();
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            }
          },
        });
      })

      .then((stream) => new Response(stream))
      .then((response) => {
        if (response.status === 200) {
          clearCart();
          alert("You have successfully placed your order! Thank you")
          setLoadingCheckout(false);
        }
      })
      .finally(() => {
        history.push("/");
      });

    // { type: "POST", data: cart }).then(response => console.log(response))
  };
  const options_ = [
    { value: "United States", label: "United States of America" },
    { value: "Ireland", label: "Ireland" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Australia", label: "Australia"  },
  ];

  const handleCountryOfResidenceChange = (selectedOption) => {
    {
      setCountryOfResidence(selectedOption);
    }
    console.log(countryOfResidence);
  };

  function calculateShippingCost(countryOfResidence) {
    if (!countryOfResidence) return;

    const cleanedCountry = countryOfResidence.trim().toLowerCase();

    if (
      cleanedCountry === "united states of america" ||
      cleanedCountry === "u s a" ||
      cleanedCountry === "united states" ||
      cleanedCountry === "america" ||
      cleanedCountry === "usa"
    ) {
      setShippingCost(6);
    } else {
      setShippingCost(15);
    }
  }

  useEffect(() => {
    calculateShippingCost(countryOfResidence);
  }, [countryOfResidence]);

  function getSteps() {
    return [
      <b
        id="enter-personal-details"
        style={{ color: "green" }}
        key={"19823741398476"}
      >
        'Enter Personal Details'
      </b>,
      <b id="enter-adress" style={{ color: "green" }} key={"91384013284091380"}>
        'Enter Address'
      </b>,
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <form className="form-group" key="001">
            <label className="w-[200px]">First Name</label>
            <input
              type="text"
              key={"913840132840askdjh"}
              value={firstName}
              id="first-name-input"
              placeholder="First Name"
              className="m-0 p-2 outline-2"
              autoComplete="true"
              onChange={(e) => setFirstName(e.target.value)}
              required
            ></input>
            <br></br>
            <label className="w-[200px]">Last Name</label>
            <input
              type="text"
              value={lastName}
              id="last-name-input"
              placeholder="Last Name"
              autoComplete="true"
             className="m-0 p-2 outline-2"
              onChange={(e) => setLastName(e.target.value)}
              required
              key={"9237rqaisuhdkasj"}
            ></input>
            <br />
            <label className="w-[200px]">Email</label>
            <input
              type="email"
              value={email}
              id="email-input"
              autoComplete="true"
             className="m-0 p-2 outline-2"
              placeholder="you@youremail.com"
              onChange={(e) => setEmail(e.target.value)}
              required
              key={"aslkdaslkje31089"}
            ></input>
            <br />
            <label className="w-[200px]">Phone Number</label>
            <input
              type="tel"
              value={phoneNumber}
              autoComplete="true"
              id="telephone-number-input"
             className="m-0 p-2 outline-2"
              placeholder="your phone number"
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            ></input>
            <br />
          </form>
        );
      case 1:
        return (
          <form className="form-group" key="003">
            <label className="w-[200px]">Mailing Address</label>
            <input
              autoComplete="true"
              type="text"
              id="street-adress-input"
              placeholder="Street Address"
              key={"aksdjhfaoewur939"}
              className="m-0 p-2 outline-2"
              onChange={(e) => setAddy(e.target.value)}
            ></input>
            <br></br>
            <label className="w-[200px]">City</label>
            <input
              type="text"
              autoComplete="true"
              placeholder="City"
              key={"aksdjasdhfaoewur939"}
              id="city-adress-input"
              className="m-0 p-2 outline-2"
              onChange={(e) => setCity(e.target.value)}
            ></input>
            <br />
            <label key={"aksdjhfasdsaoewur939"} className="w-[200px]">
              State{" "}
            </label>

            <input
              type="text"
              autoComplete="true"
              placeholder="State"
              id="state-adress-input"
              className="m-0 p-2 outline-2"
              key={"aksdjhfaoasdasewur939"}
              onChange={(e) => setStateOfResidence(e.target.value)}
            ></input>
            <br></br>
            <label className="w-[200px]">Country </label>
            {/* <Select options={options_} onChange={()=>handleCountryOfResidenceChange} autoFocus={true} /> */}
            <input
              type="text"
              autoComplete="true"
              placeholder="Country"
              key={"aksdjhfaoewur939234"}
              id="country-adress-input"
              className="m-0 p-2 outline-2"
              onChange={(e) => setCountryOfResidence(e.target.value)}
            ></input>
            <br></br>
            <label className="w-[200px]">Postal Code</label>
            <input
              type="text"
              autoComplete="true"
              key={"aksdjhfaoew5345ur939"}
              placeholder="Zip Code"
              id="zip-adress-input"
              className="m-0 p-2 outline-2"
              onChange={(e) => setZipCode(e.target.value)}
            ></input>
          </form>
        );
      default:
        return "Unknown step";
    }
  }
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    if (activeStep === 0) {
      if (firstName.trim() === "") {
        return alert("First name is required");
      }

      if (lastName.trim() === "") {
        return alert("Last name is required");
      }

      if (email.trim() === "") {
        return alert("Email is required");
      }

      if (phoneNumber.trim() === "") {
        return alert("Phone number is required");
      } else if (!/^\d{10}$/.test(phoneNumber)) {
        return alert("Phone number must be 10 digits");
      }
    }

    if (activeStep === 1) {
      if (addy.trim() === "") {
        return alert("Mailing address is required");
      }

      if (city.trim() === "") {
        return alert("City is required");
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  useEffect(() => {
    if (payPalApproved) {
      sendOrderSummaryEmails();
    }
  }, [payPalApproved]);

  return (
    <div className={[classes.root, "flex justify-center flex-col items-center h-screen lg:w-1/4 lg:ml-96 sm:ml-0 sm:w-full md:w-full md:ml-0"]}>
      <ToastContainer containerId="successfulOrder" />
      <h1 className="text-left font-bold text-2xl mt-4">
        Chubby's Checkout Stepper
      </h1>
      <br />
      {/* used for testing the email sender api  */}
      {/* <button onClick={() => setPayPalApproved(!payPalApproved)}>
        press me
      </button> */}
      {readyToCheckout ? null : (
        <Stepper style={{minWidth: "400px", marginLeft: "-100px", marginRight: "150px", alignItems: 'flex-start'}} activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                    {/* <Button onClick={()=>console.log(`SHIPPING: ${shippingCost}`)}>LOG CONSOLE SHIPPING</Button> */}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      )}
      {!readyToCheckout ? (
        activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>Your Info:</Typography>
            <Typography>Name: {firstName + " " + lastName}</Typography>
            <Typography>Email: {email}</Typography>
            <Typography>
              Amount Charged: {total + tax + shippingCost}
            </Typography>
            <Typography>address: {addy}</Typography>
            <Typography>city: {city}</Typography>
            <Typography>state: {stateOfResidence}</Typography>
            <Typography>zipCode: {zipCode}</Typography>
            <button
              onClick={handleReset}
              className={classes.button}
              style={{
                backgroundColor: "red",
                color: "#fff",
                padding: 10,
                marginHorizontal: 20,
              }}
            >
              Reset
            </button>
            <button
              onClick={() => setReadyToCheckout(true)}
              className={classes.button}
              style={{
                backgroundColor: "green",
                color: "#fff",
                padding: 10,
                marginHorizontal: 20,
              }}
            >
              Let's Checkout
            </button>
            {/* <button onClick={setPayPalApproved(true)}>TEST ME</button>x */}
          </Paper>
        )
      ) : (
        <PayPalScriptProvider
          options={{ "client-id": process.env.REACT_APP_PAYPAL_PROD_CLIENT_ID }}
        >
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: (total + shippingCost + tax).toFixed(2),
                    },
                  },
                ],
              });
            }}
            onApprove={async (data, actions) => {
              const order = await actions.order.capture();
              if (order) {
                setPayPalApproved(true);
              }
            }}
            style={{ marginLeft: 100 }}
          />
        </PayPalScriptProvider>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearCart: () => dispatch(clearCart()),
});
const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
});
export default connect(mapStateToProps, mapDispatchToProps)(ChubbysStepper);
