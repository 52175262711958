import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";
import CheckoutItem from "../CheckoutItem/CheckoutItem";
import { calculateItemCount } from "../../redux/cart/cart.utils";
import ChubbysStepper from "../FormStepper/FormStepper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import {
  selectCartItems,
  selectCartTotal,
} from "../../redux/cart/cart.selectors";
import {
  CheckoutPageContainer,
  CheckoutHeaderContainer,
  SubtotalSpan,
  CheckoutHeaderBlock,
  TotalContainer,
  CheckoutHeading,
} from "./Checkout.styles";
import { clearCart } from "../../redux/cart/cart.actions";
import CustomButton from "../CustomButton/CustomButton";
import { StylesContext } from "@material-ui/styles";
import { marketplaceData } from "../../data/MarketplaceData";
import FreebieSelector from "../FreebieSelector/FreebieSelector";
import Promo5packModal from "../Promo5PackModal/Promo5packModal";
import usePromoCode from "../PromoCode/PromoCode";
import PromoCode from "../PromoCode/PromoCode";

const CheckoutPage = ({
  cartItems,
  total,
  tax = total * 0.08,
  shipping,
  clearCart,
}) => {
  const history = useHistory();
  const [itemCount, setItemCount] = useState(0);
  const [freebieEligible, setFreebieEligible] = useState(true);

  const [freebies, setFreebies] = useState([]);
  const [promo5pack, setpromo5pack] = useState(total >= 100.01 ? true : false);
  const [numberOfSelectedFreebies, setNumberOfSelectedFreebies] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [total_, setTotal_] = useState(total);
  const [promo5packSelection, setPromo5packSelection] = useState({
    firstPromoPackSelection: null,
    secondPromoPackSelection: null,
  });
  const [promoCode, setPromoCode] = useState("");
  const [message, setMessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [promotionValue, setPromotionValue] = useState("");
  const [freebieTotal, setFreebieTotal] = useState(0);

  // 👇🏼 promo 5 pack selection logic 👇🏼
  let subtitle;
  function openModal() {
    setIsOpen(true);
  }
  function handlePromoCodeChange(Event) {
    setPromoCode(Event.target.value);
    setPromotionValue("");
  }
  function handleSubmitPromoCode(Event) {
    if (Event && typeof Event.preventDefault === "function") {
      Event.preventDefault();
    }
    if (promoCode === "") {
      setIsValid(false) && setPromotionValue("");
      return false;
    }
    promoCode && validatePromoCode(promoCode);
  }
  useEffect(() => {
    validatePromoCode(promoCode);
  }, [cartItems, total]);

  function validatePromoCode(promoCode) {
    if (!cartItems || cartItems.length === 0) {
      console.error("Cart is empty");
      clearPromoCode();
      return false;
    }
    let itemCount = calculateItemCount(cartItems);
    switch (promoCode) {
      case "Vetcodes":
        if (total >= 40.01) {
          setIsValid(true);
          setPromotionValue("Free Shipping over 40$ && 3 Pack of Freebies");
          setFreebieTotal((prev) => prev + 3);
          removeShipping();
        } else {
          setIsValid(false);
          clearPromoCode();
        }
        break;

      case "CSS3P":
        setIsValid(true);
        setPromotionValue("3 Pack of Freebies");
        setFreebieTotal((prev) => prev + 3);
        break;
      case "CSS5P":
        setIsValid(true);
        setPromotionValue("5 Pack of Freebies");
        setFreebieTotal((prev) => prev + 5);
        break;
      case "CSSBOGO":
        if (itemCount >= 3) {
          setIsValid(true);
          setPromotionValue("Buy One Get One Free");
          setFreebieTotal(itemCount);
        } else {
          setIsValid(false);
          clearPromoCode();
        }
        break;
      case "CSSFS":
        setIsValid(true);
        setPromotionValue("Free Shipping");
        removeShipping();
        break;
      case "CSSOZ":
        if (total >= 150.01) {
          setIsValid(true);
          setPromotionValue("Free Shipping over 150$");
          removeShipping();
        } else {
          setIsValid(false);
          clearPromoCode();
        }
        break;
      default:
        setIsValid(false);
        clearPromoCode();
        break;
    }
  }

  function clearPromoCode() {
    setIsValid(false);
    setPromotionValue("");
    setPromoCode("");
  }



  function removeShipping() {
    setTotal_(total - shipping);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const notifyUserSelectionsMade = () => {
    alert(
      "You have successfully selected your two free promo packs :) Please Select your Freebies next!!!"
    );
  };

  function promoOver100(total) {
    if (total >= 100.01) {
      setpromo5pack(true);
    } else {
      setPromo5packSelection({
        firstPromoPackSelection: null,
        secondPromoPackSelection: null,
      });
      setpromo5pack(false);
    }
  }
  useEffect(() => {
    if (
      promo5pack &&
      promo5packSelection.firstPromoPackSelection === null &&
      promo5packSelection.secondPromoPackSelection === null
    ) {
      openModal();
    }
  }, [promo5pack]);

  useEffect(() => {
    if (promo5packSelection[1]) {
      closeModal();
      alert(
        "You have successfully selected your 2 free Promo 5-packs. Please select your freebies above next."
      );
    }
  }, [promo5packSelection]);

  useEffect(() => {
    promoOver100();
  }, [total]);

  // 👆🏼 Promo 5 pack selection logic ends 👆🏼


  useEffect(() => {
    if (!freebies) {
      return;
    }
    window.scrollTo({ top: 300, left: 0 });
  }, []);

  useEffect(() => {
    setFreebies(marketplaceData.filter((item) => item.price === 8));
  }, []);

  useEffect(() => {
    if (total <= 1) {
      history.push("/marketplace");
    }
    let itemCount = calculateItemCount(cartItems);
    setItemCount(itemCount);

    !promoCode && setFreebieTotal(Math.floor(itemCount / 2));
  }, [cartItems, freebieTotal, itemCount]);

  const getTotalFreebiesQty = () => {
    return freebies.reduce((acc, item) => acc + item.qty, 0);
  };
  const FreeItem = ({ item }) => {
    const [quantity, setQuantity] = useState(0);

    !promoCode && setFreebieTotal(Math.floor(itemCount / 2));
    promoCode && !isValid && setFreebieTotal(Math.floor(itemCount / 2));

    // when we have 3 freebies, it should be only able to select items with price 8
    const incrementQty = () => {
      const newFreebies = [...freebies];

      const index = freebies.findIndex((_item) => _item.id === item.id);

      // total = 2
      // item count 3

      if (getTotalFreebiesQty() < freebieTotal) {
        if (index !== -1) {
          newFreebies[index].qty += 1;

          // setQuantity(quantity + 1);
        }

        setFreebies(newFreebies);
      }
    };

    const decrementQty = () => {
      setQuantity(quantity >= 1 ? quantity - 1 : 0);

      const newFreebies = [...freebies];

      const index = freebies.findIndex((_item) => _item.id === item.id);

      if (index !== -1) {
        if (newFreebies[index].qty >= 1) {
          newFreebies[index].qty -= 1;
        } else {
          newFreebies[index].qty = 0;
        }
        setFreebies(newFreebies);
      }
    };

    return (
      <div className="flex items-center flex-row my-2 ">
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="hover:bg-slate-400 rounded-lg px-2"
        >
          <img
            src={item.image}
            className="mr-2"
            style={{ width: 40, height: 40, borderRadius: 20 }}
          />
          <label className="ml-3 w-[200px]">
            {item.title} <br /> {item.strainClass}
          </label>

          <span className="flex flex-row items-center ml-2">
            <div
              className="bg-[#e8eaf3] w-[30px] h-[30px] rounded-2xl flex items-center justify-center text-black cursor-pointer"
              onClick={decrementQty}
            >
              -
            </div>
            <span className="mx-2">{item.qty}</span>
            <div
              className="bg-[#e8eaf3] w-[30px] h-[30px] rounded-2xl flex items-center justify-center text-black cursor-pointer"
              onClick={incrementQty}
            >
              +
            </div>
          </span>
        </span>
      </div>
    );
  };
  useEffect(() => {
    setRemainingFreebies(freebieTotal - getTotalFreebiesQty());
  }, [freebieTotal]);
  const totalFreebiesQty = getTotalFreebiesQty();

  const [remainingFreebies, setRemainingFreebies] = useState(
    freebieTotal - totalFreebiesQty
  );
  const freebieCounter = () => {
    if (itemCount >= 1) {
      return (
        <>
          {" "}
          <div>
            <span>
              you have{" "}
              {getTotalFreebiesQty() === 0
                ? Math.floor(remainingFreebies)
                : Math.floor(remainingFreebies) -
                  Math.floor(getTotalFreebiesQty())}{" "}
              freebie seeds to choose from
            </span>

            <FreebieSelector />
          </div>
          <div className=" flex-row w-full grid sm:grid-cols-2 grid-cols-1">
            {freebies.map((item) => (
              <React.Fragment key={item.id + "FRAGMENT"}>
                <FreeItem key={item.id} item={item} />
              </React.Fragment>
            ))}
          </div>
        </>
      );
    }
  };
  return (
    <CheckoutPageContainer>
      <ToastContainer containerId={"promoSuccessMessage"} />
      {promo5pack ? (
        <Promo5packModal
          onPromoPackSelection={setPromo5packSelection}
          subtitle={subtitle}
          modalIsOpen={modalIsOpen}
          openModal={openModal}
          closeModal={closeModal}
          afterOpenModal={afterOpenModal}
          promoSelections={promo5packSelection}
        />
      ) : null}
      <CheckoutHeading>Your Cart Contains:</CheckoutHeading>
      <CheckoutHeaderContainer>
        <CheckoutHeaderBlock style={{ marginLeft: -15, marginRight: 17.5 }}>
          <span>Product</span>
        </CheckoutHeaderBlock>
        <CheckoutHeaderBlock>
          <span>Quantity</span>
        </CheckoutHeaderBlock>
        <CheckoutHeaderBlock>
          <span>Price</span>
        </CheckoutHeaderBlock>
        <CheckoutHeaderBlock>
          <span>Remove</span>
        </CheckoutHeaderBlock>
      </CheckoutHeaderContainer>
      {cartItems.map((cartItem) => (
        <Fragment key={cartItem.id + "FRAGMENT"}>
          <CheckoutItem cartItem={cartItem} key={cartItem.id} />{" "}
        </Fragment>
      ))}
      {cartItems.length ? (
        <Fragment>
          <TotalContainer>
            <SubtotalSpan>
              {new Intl.NumberFormat("en-us", {
                style: "currency",
                currency: "USD",
                currencyDisplay: "symbol",
              }).format(total)}
              subtotal
            </SubtotalSpan>
            <SubtotalSpan>
              (+)
              {new Intl.NumberFormat("en-us", {
                style: "currency",
                currency: "USD",
                currencyDisplay: "symbol",
              }).format(tax)}{" "}
              tax
            </SubtotalSpan>
            <SubtotalSpan>
              (+) shipping -- $6-domestic $15-International
            </SubtotalSpan>
            Amount Due
            {new Intl.NumberFormat("en-us", {
              style: "currency",
              currency: "USD",
              currencyDisplay: "symbol",
            }).format(total + tax)}
            <span style={{ fontSize: "55%", fontWeight: "light" }}>
              {" "}
              plus shipping
            </span>
          </TotalContainer>
        </Fragment>
      ) : (
        <TotalContainer>
          {new Intl.NumberFormat("en-us", {
            style: "currency",
            currency: "USD",
            currencyDisplay: "symbol",
          }).format(total + tax)}
        </TotalContainer>
      )}
      <PromoCode
        handlePromoCodeChange={handlePromoCodeChange}
        promoCode={promoCode}
        message={message}
        isValid={isValid}
        promotionValue={promotionValue}
        handleSubmitPromoCode={handleSubmitPromoCode}
        validatePromoCode={validatePromoCode}
        setPromotionValue={setPromotionValue}
        setIsValid={setIsValid}
    
      />
      {freebieCounter()}

      <ChubbysStepper
        fivePackPromoSelection={promo5packSelection}
        freebies={freebies.filter((item) => item.qty > 0)}
        promoCode={promoCode}
      />
      {freebieEligible ? <FreebieSelector /> : ""}
    </CheckoutPageContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
});
const mapDispatchToProps = (dispatch) => ({
  clearCart: () => dispatch(clearCart()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
